.about-tech-stack-wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.about-description {
    background-color: #000000;
    padding: 4rem 6rem 2rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.about-description > h1 {
    color: #fff;
    font-size: 70px;
    margin-top: -100px;
}

.about-description > p {
    margin-top: 8px;
    color: #fff;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.about-tech-stack-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    /*background-color: #1e1e1e;*/
  }
  
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 180px;
    border-radius: 10px;
    background-color: #2a2a2a;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    color: white;
  }
  
  .icon img {
    width: 220px;
    height: 100px;
    margin-bottom: 0px;
  }
  
  .icon p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  
  .icon:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.4);
  }

  @media screen and (max-width: 960px) {
    .about-description > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-description > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .about-description > p {
      font-size: 30px;
    }
  }